<template>
  <div class="user-wrap">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="手机号" >
          <el-input
            clearable
            class="row input"
            v-model="searchForm.phone"
            placeholder="请输入用户手机号"
            style="width:300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="认证状态">
          <el-select
            ref="searchFormStatus"
            v-model="searchForm.status"
            clearable
            placeholder="请选择认证状态"
          >
            <el-option
              v-for="opt in AUTH_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属组织">
          <AgentTree
            :props="props"
            v-model="searchForm.unitNo"
            placeholder="请选择所属组织"
            style="width: 300px"
          ></AgentTree>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('ruleForm')">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div class="fr mb20">
        <el-button type="primary" @click="distributionUser" icon="el-icon-success" class="bkeee bdeee black">分配用户</el-button>
        <el-button type="primary" @click="addUser" icon="el-icon-success" class="bkcyan bdcyan" >新增用户</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="list"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="userAccountNo" label="账号编号" min-width="200">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          min-width="120"
          :maxlength="11"
        >
        </el-table-column>
        <el-table-column prop="personName" label="姓名" >
        </el-table-column>
        <el-table-column prop="identityStatus"  label="认证状态">
          <template slot-scope="{ row }">
            {{ $changeObj(AUTH_STATUS)[row.identityStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="agentName" label="代理商" >
        </el-table-column>
        <el-table-column prop="shopName" label="门店" >
        </el-table-column>
        <el-table-column prop="location" label="所在地区" min-width="150">
        </el-table-column>
        <el-table-column prop="authorityScheme" label="权限" >
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="200">
        </el-table-column>
        <el-table-column fixed="right" width="440" label="操作">
          <template slot-scope="scope">
            <el-button class="bkpurpleDeep bdpurpleDeep" type="primary" size="mini" @click="editUser(scope.row)">编辑</el-button>
            <el-button class="bkpurpleDeep bdpurpleDeep" type="primary" size="mini" @click="editIdentify(scope.row)">编辑认证</el-button>
            <el-button type="primary" size="mini" @click="viewContract(scope.row)">查看合同</el-button>
            <el-button class="bkpurpleDeep bdpurpleDeep" type="primary" size="mini" @click="removeUser(scope.row)">移出</el-button>
            <el-button class="bkpurpleDeep bdpurpleDeep" type="primary" size="mini" @click="editAuth(scope.row)">编辑权限</el-button>
            <!-- <el-button class="bkpurpleDeep bdpurpleDeep" type="primary" size="mini" @click="goContract(scope.row)">查看合同</el-button> -->

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 分配对象 -->
    <el-dialog title="分配用户" :visible.sync="disDialogVisible" width="30%">
      <el-form>
        <el-form-item label="选择分配对象">
          <!-- <el-cascader
            v-model="unitNos"
            :options="treeData"
            :props="props"
            style="width: 300px"
            placeholder="选择分配对象"
          >
          </el-cascader> -->
          <AgentTree
            :props="props"
            v-model="unitNos"
            placeholder="选择分配对象"
            style="width: 300px"
          ></AgentTree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveDistributetUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增 编辑用户 -->
    <el-dialog
      :title="type == 'add' ? '新增用户' : '编辑用户'"
      :visible.sync="saveUserDialogVisible"
      width="30%"
    >
      <el-form ref="useForm" :model="useForm" :rules="rules">
        <el-form-item
          label="选择代理商"
          v-if="type == 'add'"
          prop="unitNo"
        >
          <AgentTree :props="props" v-model="useForm.unitNo"></AgentTree>
        </el-form-item>
        <el-form-item
          label="账户编号"
          v-if="type == 'edit'"
          prop="userAccountNo"
        >
          <el-input
            v-model="useForm.userAccountNo"
            style="width:300px"
            disabled=""
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            placeholder="请输入手机号"
            v-model="useForm.phone"
            style="width:300px"
            :maxlength="11"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveUser('useForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑权限 -->
    <el-dialog
      title="编辑权限"
      :visible.sync="saveAuthDialogVisible"
      width="30%"
    >
      <el-form ref="authForm" :model="authForm">
        <el-form-item label="成员编号" prop="unitMemberNo">
          <el-input
            v-model="authForm.unitMemberNo"
            style="width:300px"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item label="账户编号" prop="userAccountNo">
          <el-input
            v-model="authForm.userAccountNo"
            style="width:300px"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item label="权限方案" prop="authoritySchemeNo">
          <el-select
            v-model="authoritySchemeNo"
            placeholder="请选择权限方案"
            style="width:300px"
            clearable
          >
            <el-option
              v-for="opt in authList"
              :key="opt.schemeNo"
              :value="opt.schemeNo"
              :label="opt.schemeName"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveAuthUser('useForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  removeUser,
  distributetUser,
  saveUser,
  createOrUpdate,
  getAuthoritySchemeForUser
} from "@/api/user";
import { AUTH_STATUS } from "@/js/constant";
import AgentTree from "@/components/AgentTree";

import Reg from "@/js/reg";
export default {
  data() {
    const validPhone = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!Reg.isPoneAvailable(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {

      searchForm: {
        phone: "", //手机号
        status: "", // 认证状态
        unitNo: [] //代理商
      },
      AUTH_STATUS,
      beginIndex: 1,
      pageSize: 20,
      total: 0,
      list: [],
      /**选择的设备 */
      selectList: [],
      // 弹出层 分配用户
      disDialogVisible: false,
      // 弹出层 新增用户
      saveUserDialogVisible: false,
      //弹出层 编辑权限
      saveAuthDialogVisible: false,
      loading: false,
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true,
      },
      unitNos: [],
      //新增/编辑用户
      useForm: {
        phone: "", //手机号
        userAccountNo: "", //账户编号
        unitNo: '',
      },
      type: "", //add新增用户 edit编辑用户
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validPhone, trigger: "blur" }
        ],
        unitNo: [
          { required: true, message: "请选择代理商/门店", trigger: "change" },
        ],
      },
      authForm: {
        userAccountNo: "",
        unitMemberNo: "",
        unitNo: "",
      },
      authoritySchemeNo: '',
      authList: []
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormStatus.blur()
        _this.onSearch('ruleForm')
      }
    };
    this.getUserList();
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/editIdentify' || from.path === '/leaseholdMgmt/viewContract') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    this.getUserList();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.getUserList();
    },
    async getUserList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize
      };
      const unitNo = this.searchForm.unitNo[this.searchForm.unitNo.length - 1];
      params.unitNo = unitNo;
      const res = await getUserList(params);
      this.list = res.ldata;
      this.total = +res.mdata.total || 0;
    },
    /** 新增用户 */
    addUser() {
      this.saveUserDialogVisible = true;
      this.type = "add";
    },
    /**编辑用户 */
    editUser(row) {
      this.saveUserDialogVisible = true;
      this.useForm = {
        userAccountNo: row.userAccountNo,
        phone: row.phone
      };
      this.type = "edit";
    },
    saveUser() {
      this.$refs.useForm.validate(async valid => {
        if (valid) {
          if (this.loading) {
            return;
          }
          this.loading = true;
          const params = {
            phone: this.useForm.phone
          };
          if (this.type == "add") {
            params.unitNo = this.useForm.unitNo[ this.useForm.unitNo.length - 1];
          }
          if (this.type == "edit") {
            params.userAccountNo = this.useForm.userAccountNo;
          }
          try {
            await saveUser(params);
            this.saveUserDialogVisible = false;
            let tips = this.type == "add" ? "新增用户成功" : "编辑用户成功";
            this.$message.success(tips);
            this.loading = false;
            this.onSearch();
            this.$refs.useForm.resetFields();
          } catch (error) {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getUserList();
    },
    /**移出用户 */
    removeUser(row, type) {
      this.$confirm("是否确定移出用户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            unitMemberNo: row.unitMemberNo
          };
          if (type == "all") {
            params.userAccountNo = JSON.stringify(
              this.selectList.map(el => el.userAccountNo)
            );
          }
          await removeUser(params);
          this.$message.success("移出成功");
          this.beginIndex = 1;
          this.getUserList();
        })
        .catch(() => {});
    },
    /** 选择 */
    handleSelectionChange(row) {
      this.selectList = row;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**打开弹窗-分配用户 */
    distributionUser() {
      if (!this.selectList.length) {
        this.$message.error("请选择用户");
        return;
      }
      this.disDialogVisible = true;
    },
    async saveDistributetUser() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = {
        unitNo: this.unitNos[this.unitNos.length - 1],
        userAccountNoList: JSON.stringify(
          this.selectList.map(el => el.userAccountNo)
        )
      };
      try {
        await distributetUser(params);
        this.disDialogVisible = false;
        this.loading = false;
        this.$message.success("分配成功");
        this.onSearch();
      } catch (error) {
        this.loading = false;
      }
    },
    /**编辑认证 */
    editIdentify(row) {
      this.$router.push({
        path: "/leaseholdMgmt/editIdentify",
        query: {
          userAccountNo: row.userAccountNo
        }
      });
    },
    /**查看合同 */
    viewContract(row) {
      this.$router.push({
        path: "/leaseholdMgmt/viewContract",
        query: {
          userAccountNo: row.userAccountNo,
          unitMemberNo: row.unitMemberNo
        }
      });
    },
    async getAuthoritySchemeForUser($unitNo, $memberNo) {
      let params = {
        unitNo: $unitNo,
        memberNo: $memberNo
      };
      const res = await getAuthoritySchemeForUser(params);
      this.authoritySchemeNo = res.mdata.selectedScheme[0];
      this.authList = res.ldata;
    },
    //编辑权限
    editAuth(row) {
      this.saveAuthDialogVisible = true;
      this.authForm = row;
      this.getAuthoritySchemeForUser(row.unitNo, row.unitMemberNo);
    },
    saveAuthUser() {
      this.$refs.authForm.validate(async valid => {
        if (valid) {
          if (this.loading) {
            return;
          }
          this.loading = true;
          let params = {
            unitMemberNo: this.authForm.unitMemberNo,
            unitNo: this.authForm.unitNo
          };
          if (this.authoritySchemeNo) {
            params.authoritySchemeNo = [this.authoritySchemeNo];
          } else {
            params.authoritySchemeNo = [];
          }
          try {
            await createOrUpdate(JSON.stringify(params));
            this.saveAuthDialogVisible = false;
            this.$message.success("权限编辑成功");
            this.loading = false;
            this.onSearch();
            this.$refs.authForm.resetFields();
          } catch (error) {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    }
  },
  components: {
    AgentTree
  }
};
</script>

<style scoped lang="less">
.user-wrap {
  padding-bottom: 80px;
}
.filter-tree {
  margin-left: 100px;
}
</style>
