import axios from "../js/ajax";
/** 用户列表 */
export const getUserList = params =>
  axios.get("/managementPlatform/user/userAccount/list", {
    params,
    loading: true
  });
/** 新增/编辑用户 */
export const saveUser = params =>
  axios.post("/managementPlatform/user/addOrEditUserAccount", params);
/** 查询认证信息 */
export const queryIdentityInfo = params =>
  axios.get("/managementPlatform/user/queryIdentityInfo", { params });
/** 编辑认证信息 */
export const editIdentityInfo = params =>
  axios.post("/managementPlatform/user/editIdentityInfo", params);
/** 查看合同 */
export const queryUserContractSignRecord = params =>
  axios.get("/managementPlatform/user/queryUserContractSignRecord", {
    params,
    loading: true
  });
/** 移出用户 */
export const removeUser = params =>
  axios.get("/managementPlatform/user/userAccount/remove", { params });
/** 分配用户 */
export const distributetUser = params =>
  axios.post("/managementPlatform/user/userAccount/distribute", params);
/** 编辑成员权限 */
export const createOrUpdate = params =>
  axios.postJson("/admin/unit/member/authorityScheme/createOrUpdate", params);
/**获取用户在指定组织下的权限方案 */
export const getAuthoritySchemeForUser = params =>
  axios.get("/admin/unit/member/authorityScheme/list", { params });

