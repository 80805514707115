<template>
  <div class="contarct-wrap">
    <el-table :data="list" style="width: 100%">
      <!-- <el-table-column prop="id" label="外部合同号" min-width="150">
      </el-table-column> -->
      <el-table-column prop="contractNo" label="合同编号" min-width="150"></el-table-column>
      <el-table-column prop="channelType" label="通道类型" min-width="150"></el-table-column>
      <el-table-column prop="type" label="合同类型" min-width="150"></el-table-column>
      <el-table-column prop="status" width="150" label="状态"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="150"></el-table-column>
      <el-table-column fixed="right" width="150" label="操作">
        <template slot-scope="{ row }">
           <a 
            v-if="row.channelTypeCode == 2 && row.penetrateSignNo && row.status == '已生效'" 
            class="text-btn"
            :href="getUrl(row.penetrateSignNo)"
            :download="getUrl(row.penetrateSignNo)"
          >
          下载合同
          </a>
          
          <!-- <a
            v-if="row.channelTypeCode == 2"
            :href="'https://leasehold-dev.lititi.com/sign/bestSignDownloadContract?contractId=' + row.id"
            :download="'https://leasehold-dev.lititi.com/sign/bestSignDownloadContract?contractId=' + row.id"
            >下载合同</a
          > -->
          <!-- <a
            v-if="row.channelTypeCode == 2"
            :href="'/sign/bestSignDownloadAttachment?contractId=' + row.id"
            >下载存证</a
          > -->
        </template>
      </el-table-column>
    </el-table>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { queryUserContractSignRecord } from "@/api/user";
export default {
  data() {
    return {
      beginIndex: 1,
      pageSize: 20,
      total: 0,
      list: [],
      loading: false,
      path: ''
    };
  },
  mounted() {
   this.path = process.env.NODE_ENV === 'development' ? 'https://leasehold-dev.lititi.com' : `${location.origin}`;
    this.queryUserContractSignRecord();
  },
  methods: {
    async queryUserContractSignRecord() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        userAccountNo: this.$route.query.userAccountNo,
        unitMemberNo: this.$route.query.unitMemberNo,
        beginIndex: page,
        pageSize: this.pageSize
      };
      const res = await queryUserContractSignRecord(params);
      this.list = res.ldata;
      this.total = res.mdata.total;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getUserList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**编辑认证 */
    editAuth(row) {
      this.$router.push({
        path: "/leaseholdMgmt/editAuth",
        query: {
          userAccountNo: row.userAccountNo
        }
      });
    },
    getUrl(number) {
      const str = `${this.path}/bankDirectCredit/contract/${number}/TP_BLOCKCHAIN_HAILI_001/${number}_TP_BLOCKCHAIN_HAILI_001.pdf`
      return str
    }
  }
};
</script>

<style scoped lang="less">
.contarct-wrap {
  padding-bottom: 80px;
}
.text-btn {
  color: #409EFF;
}
</style>
