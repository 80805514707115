<template>
  <el-form
    class="form"
    ref="form"
    :model="form"
    :rules="rules"
    label-width="150px"
  >
    <el-form-item label="账户编号" prop="userAccountNo">
      <el-input
        v-model="form.userAccountNo"
        style="width: 300px"
        disabled
      ></el-input>
    </el-form-item>
    <el-form-item label="手机号" prop="phone">
      <el-input v-model="form.phone" style="width: 300px" disabled></el-input>
    </el-form-item>
    <!-- <el-form-item label="紧急联系人" prop="emergencyContactPhoneNo">
      <el-input
        v-model="form.emergencyContactPhoneNo"
        style="width: 300px"
        disabled
      ></el-input>
    </el-form-item> -->
    <el-form-item label="姓名" prop="personName">
      <el-input
        v-model="form.personName"
        style="width: 300px"
        placeholder="请输入姓名"
      ></el-input>
    </el-form-item>
    <el-form-item label="身份证" prop="identityNo">
      <el-input
        v-model="form.identityNo"
        style="width: 300px"
        placeholder="请输入身份证"
        :minlength="15"
        :maxlength="18"
      ></el-input>
    </el-form-item>
    <el-form-item label="审核状态" prop="identityStatusCode">
      <el-select
        v-model="form.identityStatusCode"
        clearable
        placeholder="请选择认证状态"
        style="width: 300px"
      >
        <el-option
          v-for="opt in AUTH_STATUS"
          :key="opt.value"
          :value="opt.value"
          :label="opt.label"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="picture1" label="身份证人像">
      <el-image :src="form.picture1" 
        style="width: 400px; height: 300px">
         <div slot="error" class="image-slot">
          <span style="color:#c7c9d0">暂无图片</span>
        </div>
      </el-image>
    </el-form-item>
    <el-form-item prop="picture2" label="身份证国徽">
      <el-image :src="form.picture2" 
        style="width: 400px; height: 300px">
        <div slot="error" class="image-slot">
          <span style="color:#c7c9d0">暂无图片</span>
        </div>
        </el-image>
    </el-form-item>
    <el-form-item prop="picture4" label="手持身份证照片">
      <el-image :src="form.picture4" 
        style="width: 400px; height: 300px">
        <div slot="error" class="image-slot">
          <span style="color:#c7c9d0">暂无图片</span>
        </div>
        </el-image>
    </el-form-item>
    <!-- <el-form-item
      prop="picture1"
      v-for="(item, index) in pictureList"
      :key="index"
      :label="index == 1 ? '' : item.name"
      :class="{ identify: index < 2 }"
    >
      <el-image
        :src="item.number"
        style="width: 400px; height: 300px"
        :preview-src-list="getPreviewImgList(index)"
      >
        <div slot="error" class="image-slot">
          <span style="color:#c7c9d0">暂无图片</span>
        </div>
      </el-image>
    </el-form-item> -->
    <el-form-item class="mt20">
      <el-button type="primary" @click="submitForm" :loading="disaled"
        >确认</el-button
      >
      <el-button type="info" @click="goBack">返回</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { queryIdentityInfo, editIdentityInfo } from "@/api/user";
import { AUTH_STATUS } from "@/js/constant";
// import Reg from "@/js/reg";
export default {
  data() {
    // const validCard = (rule, value, callback) => {
    //   if (value == "") {
    //     callback();
    //   } else if (!Reg.idCardValidate(value)) {
    //     callback(new Error("请输入正确的身份证号"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      form: {
        userAccountNo: "",
        phone: "",
        emergencyContactPhoneNo: "",
        personName: "",
        identityNo: "",
        status: "",
        identityStatusCode: ""
      },
      pictureList: [
        {
          name: "身份证正反面",
          number: "picture1"
        },
        {
          name: "身份证正反面",
          number: "picture2"
        },
        // {
        //   name: "工作凭证",
        //   number: "picture3"
        // },
        {
          name: "手持身份证照片",
          number: "picture4"
        },
        // {
        //   name: "人车合照",
        //   number: "picture5"
        // },
        // {
        //   name: "车牌合照",
        //   number: "picture6"
        // }
      ],
      rules: {
        personName: [
          { required: true, message: "请输入姓名", trigger: "blur" }
        ],
        identityNo: [
          { required: true, message: "请输入身份证号", trigger: "blur" }
          //   { validator: validCard, trigger: "blur" }
        ],
        status: [
          { required: true, message: "请选择审核状态", trigger: "change" }
        ]
      },
      disaled: false,
      AUTH_STATUS,
      pictureList1: []
    };
  },
  mounted() {
    this.queryIdentityInfo();
  },
  methods: {
    /*获取认证信息*/
    async queryIdentityInfo() {
      const params = {
        userAccountNo: this.$route.query.userAccountNo
      };
      const res = await queryIdentityInfo(params);
      this.form = res.mdata.identityInfo;
    },
    /*编辑认证信息*/
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.disaled = true;
          const params = {
            appCode: 9,
            userAccountNo: this.form.userAccountNo,
            personName: this.form.personName,
            identityNo: this.form.identityNo,
            status: this.form.identityStatusCode
          };
          try {
            const res = await editIdentityInfo(params);
            this.disaled = false;
            this.$message.success("认证成功");
            setTimeout(() => {
              this.$router.push("/leaseholdMgmt/user");
            }, 1000);
          } catch (error) {
            this.disaled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/user');

    },
    getPreviewImgList(index) {
      let arr = [];
      for (let i = 0; i < this.pictureList.length; i++) {
        arr.push(this.pictureList[i + index]);
        if (i + index >= this.pictureList.length - 1) {
          index = 0 - (i + 1);
        }
      }
      return arr;
    }
  }
};
</script>

<style scoped>
.identify {
  display: inline-block;
}
.el-image {
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
